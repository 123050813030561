<template>
  <v-card outlined style="position: fixed" max-width="100%" width="20%">
    <v-card-text class="pa-2">
      <v-list dense>
        <v-list-item dense>
          <v-list-item-content>
            <v-autocomplete
              ref="modules"
              outlined
              chips
              small-chips
              dense
              color="primary"
              :label="$t('notifications.titles.module')"
              item-color="primary"
              hide-details
              :items="modules"
              item-text="text"
              item-value="value"
              multiple
              clearable
              @input="$emit('set:filters', { modules: $event })"
              v-model="sModules"
            >
              <template v-slot:prepend-item>
                <v-checkbox
                  v-model="checkboxState.modules"
                  color="primary"
                  class="pt-2 pb-2 mt-0 pl-4"
                  :hide-details="true"
                  @change="selectAll('modules', 'sModules')"
                >
                  <template v-slot:label>
                    <v-btn small
                           color="primary" x-small
                           text>
                      {{$t('general.titles.selectAll')}}
                    </v-btn>
                  </template>
                </v-checkbox>
                <v-divider class="mb-2"></v-divider>
              </template>
              <template v-slot:prepend-inner>
                <v-icon small left class="mt-1">
                  mdi-view-module-outline
                </v-icon>
              </template>
              <template v-slot:item="{ item, attrs }">
                <div style="display: contents">
                  <v-checkbox
                    dense
                    :input-value="attrs.inputValue"
                    color="primary"
                    class="pt-2 pb-2 mt-0 priority__text"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      {{ item.text }}
                    </template>
                  </v-checkbox>
                </div>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip small v-if="index === 0">
                  <span>{{ item.text }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text caption"
                >(+{{ sModules.length - 1 }}
                  {{ $t("general.titles.others") }})</span
                >
              </template>
            </v-autocomplete>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense>
          <v-list-item-content>
            <v-autocomplete
              outlined
              chips
              small-chips
              dense
              color="primary"
              :label="$t('notifications.titles.visibility')"
              item-color="primary"
              hide-details
              :items="watched"
              item-text="text"
              item-value="value"
              clearable
              @input="$emit('set:filters', { watched: $event })"
            >
              <template v-slot:prepend-inner>
                <v-icon small left class="mt-1">
                  mdi-eye-outline
                </v-icon>
              </template>
            </v-autocomplete>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense>
          <v-list-item-content>
            <v-autocomplete
              outlined
              chips
              small-chips
              dense
              color="primary"
              :label="$t('notifications.titles.priority')"
              item-color="primary"
              hide-details
              :items="priorities"
              item-text="text"
              item-value="value"
              multiple
              clearable
              v-model="sPriorities"
              @input="$emit('set:filters', { priorities: $event })"
            >
              <template v-slot:prepend-inner>
                <v-icon small left class="mt-1">
                  mdi-priority-high
                </v-icon>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip small v-if="index === 0">
                  <span>{{ item.text }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text caption"
                >(+{{ sPriorities.length - 1 }}
                  {{ $t("general.titles.others") }})</span
                >
              </template>
              <template v-slot:item="{ item, attrs }">
                <div style="display: contents">
                  <v-checkbox
                    dense
                    :input-value="attrs.inputValue"
                    color="primary"
                    class="pt-2 pb-2 mt-0 priority__text"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      {{ item.text }}
                    </template>
                  </v-checkbox>
                  <v-spacer/>
                  <v-icon :color="item.color">
                    {{ item.icon }}
                  </v-icon>
                </div>
              </template>
            </v-autocomplete>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import i18n from "@/plugins/i18n";
import ReadMore from "@/components/ReadMoreComponent.vue";
import _ from "lodash";

export default {
  name: "NotificationFilters",
  components: {ReadMore},

  data: () => ({
    sModules: [],
    sPriorities: [],
    checkboxState: {
      modules: false
    }
  }),

  computed: {
    modules: () => [{
      text: 'BRAIN_AG',
      value: 'BRAIN_AG'
    }, {
      text: i18n.t('modules.name.Record').toUpperCase(),
      value: ['RECORDS', 'RECORD_VALIDATION']
    }, {
      text: i18n.t('reports.titles.traceability').toUpperCase(),
      value: 'TRACEABILITY'
    }, {
      text: i18n.t('menu.stock-report').toUpperCase(),
      value: 'STOCK'
    }, {
      text: i18n.t('notifications.titles.orders').toUpperCase(),
      value: 'USER_LOCATIONS'
    }],
    watched: () => [{
      text: i18n.t('notifications.titles.read').toUpperCase(),
      value: 'true'
    }, {
      text: i18n.t('notifications.titles.unread').toUpperCase(),
      value: 'false'
    }],
    priorities: () => [{
      text: i18n.t('notifications.titles.normal').toUpperCase(),
      value: 'NORMAL',
      icon: 'mdi-minus',
      color: 'success'
    }, {
      text: i18n.t('notifications.titles.medium').toUpperCase(),
      value: 'MEDIUM',
      icon: 'mdi-equal',
      color: 'warning'
    }, {
      text: i18n.t('notifications.titles.high').toUpperCase(),
      value: 'HIGH',
      icon: 'mdi-chevron-double-up',
      color: 'error'
    }]
  },

  methods: {
    selectAll(target, field) {
      if (this.checkboxState[target] === false) {
        this[field] = []
      } else {
        let aux = []
        if (this[field] && this[field].length > 0) {
          _.forEach(this[field], object => {
            if (
              !_.find(this.$refs[target].items, o => {
                return o.id === object.id
              })
            )
              this.$refs[target].items.push(object)
          })
          aux = this.$refs[target].items
        } else {
          aux = this.$refs[target].items
        }
        this[field] = aux
      }
      this.$emit('set:filters', { [target]: _.map(this[field], d => d.value) })
    },
  },

  destroyed() {
    this.sModules = []
    this.sPriorities = []
  }
}
</script>

<style scoped>
.priority__text :deep(label) {
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1rem;
  color: #AAAAAA;
}
</style>